import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Alert,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import ReactQuill from 'react-quill';
// import AsyncSelect from 'react-select/async';
import Select from 'react-select';

import './SendEmailModal.scss';
import { getRoles } from '../../../modules/companies/CompaniesPage.helper';
import { postEmail } from './send-email-helper';
import { toast } from 'react-toastify';
import { getCompaniesById } from '../../../modules/CompanyPage/CompanyPage.helper';
import Loader from '../loaders/Loader';

function SendEmailModal({ toggle, status, data, origin }) {
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const companies =
      origin === 'main' ? selectedCompanies : [{ value: data.id }];
    if (companies.length === 0)
      return setErrorMessage('Please select at least one Company');
    if (selectedRole === null) return setErrorMessage('Please select a Role');
    if (selectedRole === 'Select a role')
      return setErrorMessage('Please select a Role');
    if (subject === '') return setErrorMessage('Subject cannot be empty');
    if (message === '') return setErrorMessage('Message cannot be empty');
    setErrorMessage(null);
    setLoading(true);
    const fullCompanies = await Promise.all(
      companies.map((company) => getCompaniesById(company.value)),
    );
    console.log(
      'DEBUG:SendEmailModal: handleSubmit: fullCompanies:',
      selectedRole,
    );
    const users = [];
    fullCompanies.forEach((company) => {
      company.companyDetails.users.forEach((user) => {
        if (Number(user.roleId) === Number(selectedRole)) {
          if (user.status === 'ACTIVE') users.push(user);
        }
      });
    });
    console.log('DEBUG: SendEmailModal : handleSubmit: users', users);
    const emails = users.map((user) => user.email);
    console.log('DEBUG: SendEmailModal -> handleSubmit: emails', emails);
    const res = await postEmail({ subject, message, emails });
    setLoading(false);
    if (!res.success) return toast.error('Something went wrong');
    toggle();
    toast.success('Emails sent');
  };

  const handleRoleChange = ({ target }) => {
    setSelectedRole(target.value);
  };

  useEffect(() => {
    // get the roles to select whom send the message to
    getRoles()
      .then((res) => {
        setRoles(res);
      })
      .catch((error) => {
        console.log('error fetching roles', error.message);
      });
  }, []);

  const options = [];
  if (data) {
    if (Array.isArray(data)) {
      data.forEach((company) => {
        options.push({
          value: company.id,
          label: company.name,
        });
      });
    }
  }
  const handleSelectCompany = async (selectedCompanies) => {
    setSelectedCompanies(selectedCompanies);
  };

  return (
    <Modal
      className={'SendEmailModal'}
      centered={true}
      toggle={toggle}
      isOpen={status}
    >
      <ModalHeader>Send Email</ModalHeader>
      <ModalBody>
        <Form>
          {origin === 'main' && (
            <FormGroup>
              <Label>What companies are you going to send the email to?</Label>
              {/*<AsyncSelect loadOptions={loadOptions} isMulti defaultOptions onChange={handleSelectCompanies} />*/}
              <Select
                options={options}
                isMulti
                onChange={handleSelectCompany}
              />
            </FormGroup>
          )}
          <FormGroup>
            <Label>Whom in the company do you want to send the email to?</Label>
            <Input
              type="select"
              onChange={handleRoleChange}
              defaultValue={null}
            >
              <option value={null}>Select a role</option>
              {roles.map((role) => (
                <option key={role.id} value={role.id}>
                  {role.name}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>Subject</Label>
            <Input
              placeholder="Email Subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required={true}
              type="text"
              id="subject"
            />
          </FormGroup>
          <FormGroup>
            <Label>Message</Label>
            <ReactQuill
              value={message}
              onChange={(value) => setMessage(value)}
            />
            {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle}>Cancel</Button>
        <Button color="primary" type={'submit'} onClick={handleSubmit}>
          {loading ? <Loader height={'40px'} width={'40px'} /> : 'Send Email'}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default SendEmailModal;
