import axios from 'axios';
import endpoints from '../../../services/endpoints';
import { getAccessToken } from '../../../services/token.utils';

export const registerCompany = async ({
  firstName,
  lastName,
  phoneNumber,
  companyName,
  email,
  password,
}) => {
  try {
    let res = await axios.post(
      endpoints.registerCompanyAPI(),
      {
        firstName,
        lastName,
        phoneNumber,
        companyName,
        email,
        password,
      },
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    );
    return res.status === 200;
  } catch (e) {
    console.error(e.message + 'here');
    return false;
  }
};
