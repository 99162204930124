import React, { useEffect, useState } from 'react';
import './Sidebar.scss';
import { Menu } from './Sidebar.data';
import { useHistory, useLocation } from 'react-router-dom';

function Sidebar() {
  const [currentURL, setCurrentURL] = useState('');
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    setCurrentURL(location.pathname);
  }, [location]);
  return (
    <div className={'Sidebar'}>
      {Menu.map((item, index) => {
        return (
          <div
            className={`menu-item ${
              currentURL.includes(item.link) ? 'active' : ''
            }`}
            onClick={() => history.push(item.link)}
            key={index}
          >
            <img src={item.icon} />
            <div className="menu-item-text">{item.title}</div>
          </div>
        );
      })}
    </div>
  );
}

export default Sidebar;
