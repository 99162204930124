import LoadingGif from './Loading.gif';

import './Loader.scss';

const Loader = ({ width, height }) => {
  const finalWidth = width || 100;
  const finalHeight = height || 100;
  return (
    <div className={'Loader'}>
      <img
        src={LoadingGif}
        alt="LoadingGif"
        style={{ width: finalWidth, height: finalHeight }}
      />
    </div>
  );
};
export default Loader;
