import { useEffect, useState } from 'react';
import { getQuote } from './LoginPage.data';

function JesusQuote() {
  const [sideInfo, setSideInfo] = useState({
    quote: '',
    author: '',
  });
  useEffect(() => {
    const { quote, author } = getQuote();
    setSideInfo({ quote, author });
  }, []);

  return (
    <div className="quote-container JesusQuote">
      <style>
        {`
           body {
            width: 100% !important;
            max-width: 100% !important;
            padding: 0 !important;  
          }
          main>.container{
            width: 100% !important;
            max-width: 100% !important;
            padding: 0 !important;
          }
          body .fixed-background{
            background-size: unset;
            width: 0%;
            height: 0%;
          }
        `}
      </style>
      <div>
        <div className="title">Jesus Loves You</div>
        <p className="quote">{sideInfo.quote}</p>
        <p className="sub-quote">{sideInfo.author}</p>
      </div>
    </div>
  );
}

export default JesusQuote;
