import React, { useState } from 'react';
import {
  Button,
  Input,
  Label,
  Modal,
  FormGroup,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { validatePassword } from '../../password';
import Loader from '../loaders/Loader';
import { resetPassword } from '../../../modules/companies/CompaniesPage.helper';

function ResetPasswordModal({ toggle, status, data: { id: companyId } }) {
  const [state, setState] = useState({ password: '', repeatedPassword: '' });
  const [loading, setLoading] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    if (state.password !== state.repeatedPassword) {
      toast.error('Password and Repeated Password must be same!');
      return;
    }
    const validation = validatePassword(state.password);
    if (validation.length > 0) {
      const validationErrors = validation.map((error) => error.message);
      toast.error('Invalid Password: ' + validationErrors.join(', '));
      return;
    }
    setLoading(true);
    resetPassword(companyId, state.password)
      .then((isDone) => {
        if (!isDone) toast.error('Error While resetting password try again!');
        else {
          toast.success('Password reset successfully!');
          toggle();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      className={'ResetPasswordModal'}
      centered={true}
      toggle={toggle}
      isOpen={status}
    >
      <form onSubmit={onSubmit}>
        <ModalHeader>Reset Password</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Password</Label>
            <Input
              placeholder="Enter new Company Password"
              value={state.password}
              onChange={(e) => setState({ ...state, password: e.target.value })}
              required={true}
              type="password"
            />
          </FormGroup>
          <FormGroup>
            <Label>Confirm Password</Label>
            <Input
              placeholder="Repeat new Company Password"
              value={state.repeatedPassword}
              onChange={(e) =>
                setState({ ...state, repeatedPassword: e.target.value })
              }
              required={true}
              type="password"
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle}>Cancel</Button>
          <Button color="primary" type={'submit'}>
            {loading ? <Loader height={'40px'} width={'40px'} /> : 'Reset'}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}

export default ResetPasswordModal;
