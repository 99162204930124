import React, { useRef, useState } from 'react';
import { FaSort } from 'react-icons/fa';

import StickyHeader from '../../../shared/components/StickyHeader';
import './CompanyBranchDetails.scss';

function CompanyBranchDetails({ data = [], users = [] }) {
  const branchesListRef = useRef(null);
  const [sortKey, setSortKey] = useState(null);
  const [orderDirectionAsc, setOrderDirectionAsc] = useState(true);

  // Calculating Users
  data.forEach((branch) => {
    const branchUsers = users.filter((user) => user.branchId === branch.id);
    branch.users = branchUsers;
  });

  const toggleSort = (key) => {
    if (key === sortKey) {
      setOrderDirectionAsc(!orderDirectionAsc);
    } else {
      setSortKey(key);
      setOrderDirectionAsc(true);
    }
  };

  if (sortKey !== null && data.length > 0) {
    data.sort((a, b) => {
      if (sortKey === 'id') return Number(a[sortKey]) - Number(b[sortKey]);
      if (sortKey === 'users') return a.users.length - b.users.length;
      if (sortKey === 'sub-branches')
        return a.subBranches.length - b.subBranches.length;
      const aValue = String(a[sortKey]);
      const bValue = String(b[sortKey]);
      return aValue.localeCompare(bValue);
    });
    if (!orderDirectionAsc) data.reverse();
  }

  const branchTile = (item = {}, index = 0) => {
    return (
      <div className={'item'} key={index}>
        <p className="single last-login ">{item.id}</p>
        <p className="single name ">{item.name}</p>
        <p className="single average-activity-time text-center">
          {users.filter((a) => a.branchId === item.id).length}
        </p>
        <p className="single most-visited-pages text-center">
          {item.subBranches.length}
        </p>
      </div>
    );
  };

  return (
    <div className={'CompanyBranchDetails'}>
      <div className="header">Branches ({data.length})</div>
      <StickyHeader
        bodyRef={branchesListRef}
        topPositionHeader={0}
        thresholdSticky={0}
      >
        <div className="key-labels">
          <p className="key last-login " onClick={() => toggleSort('id')}>
            ID <FaSort />
          </p>
          <p className="key name " onClick={() => toggleSort('name')}>
            Name <FaSort />
          </p>
          <p
            className="key average-activity-time text-center"
            onClick={() => toggleSort('users')}
          >
            Users <FaSort />
          </p>
          <p
            className="key most-visited-pages text-center"
            onClick={() => toggleSort('sub-branches')}
          >
            Sub Branches <FaSort />
          </p>
        </div>
      </StickyHeader>
      <div className="items-container" ref={branchesListRef}>
        {data.map(branchTile)}
      </div>
    </div>
  );
}

export default CompanyBranchDetails;
