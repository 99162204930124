import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import './App.scss';
import 'react-quill/dist/quill.snow.css';
import 'react-toastify/dist/ReactToastify.css';
import CompaniesPage from './modules/companies';
import PrivateRoute from './shared/components/PrivateRoute';
import CompanyPage from './modules/CompanyPage';
import UserLayout from './shared/components/layout/UserLayout';
import DashboardPage from './modules/DashboardPage';
import LoginPage from './modules/LoginPage';
import EmailPage from './modules/email-page/EmailPage';

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

function App() {
  return (
    <>
      <Helmet>
        <title>Insightt System Admin Dashboard</title>
      </Helmet>
      <BrowserRouter>
        <React.Suspense fallback={loading()}>
          <UserLayout>
            <Switch>
              ``
              <Route
                exact={true}
                path="/login"
                name="Home Page"
                render={(props) => <LoginPage {...props} />}
              />
              <PrivateRoute component={DashboardPage} path="/dashboard" exact />
              <PrivateRoute component={CompaniesPage} path="/company" exact />
              <PrivateRoute component={CompanyPage} path="/company/:id" exact />
              <PrivateRoute component={EmailPage} path="/emails" exact />
              <Route
                exact={true}
                path="*"
                render={() => <Redirect to="/login" />}
              />
            </Switch>
            <ToastContainer />
          </UserLayout>
        </React.Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
