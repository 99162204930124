import React, { useState } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { registerCompany } from './AddNewCompanyModal.helper';
import './AddNewCompanyModal.scss';
import { generatePassword } from '@insightt/insightt-shared';
import Loader from '../loaders/Loader';
import { validatePassword } from '../../password';
import { setPhoneNumberFormat } from '../../../services/utils';

function AddNewCompanyModal({ toggle, status }) {
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    companyName: '',
    email: '',
    password: generatePassword(),
  });
  const [loading, setLoading] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    const validation = validatePassword(state.password);
    if (validation.length > 0) {
      const validationErrors = validation.map((error) => error.message);
      toast.error('Invalid Password: ' + validationErrors.join(', '));
      return;
    }

    if (state.phoneNumber.length < 14) {
      toast.error('Please enter a valid phone number');
      return;
    }

    const plainPhoneNumber = state.phoneNumber.replace(/[^\d]/g, '');

    setLoading(true);
    registerCompany({
      ...state,
      phoneNumber: `+1${plainPhoneNumber}`,
    })
      .then((isDone) => {
        if (!isDone) toast.error('Error While creating new company try again!');
        else toast.success('Company Created Successfully!');
      })
      .finally(() => {
        setLoading(false);
        setState({
          companyName: '',
          firstName: '',
          lastName: '',
          phoneNumber: '',
          email: '',
          password: '',
        });
        toggle();
      });
  };

  return (
    <Modal
      className={'AddNewCompanyModal'}
      centered={true}
      toggle={toggle}
      size="lg"
      isOpen={status}
    >
      <form onSubmit={onSubmit}>
        <ModalHeader>Add Company</ModalHeader>
        <ModalBody>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>First Name</Label>
                <Input
                  placeholder="Enter First Name"
                  value={state.firstName}
                  onChange={(e) =>
                    setState({ ...state, firstName: e.target.value })
                  }
                  required={true}
                  type="text"
                  data-cy="firstName"
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Last Name</Label>
                <Input
                  placeholder="Enter Last Name"
                  value={state.lastName}
                  onChange={(e) =>
                    setState({ ...state, lastName: e.target.value })
                  }
                  required={true}
                  type="text"
                  data-cy="lastName"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Company Name</Label>
                <Input
                  placeholder="Enter Company Name"
                  value={state.companyName}
                  onChange={(e) =>
                    setState({ ...state, companyName: e.target.value })
                  }
                  required={true}
                  type="text"
                  data-cy="companyName"
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Company Email</Label>
                <Input
                  placeholder="Enter Company Email"
                  value={state.email}
                  onChange={(e) =>
                    setState({ ...state, email: e.target.value })
                  }
                  required={true}
                  type="email"
                  data-cy="companyEmail"
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <FormGroup>
                <Label>Phone Number</Label>
                <Input
                  placeholder="Enter Phone Number"
                  value={setPhoneNumberFormat(state.phoneNumber)}
                  onChange={(e) => {
                    setState({
                      ...state,
                      phoneNumber: e.target.value,
                    });
                  }}
                  pattern="[0-9()-]{14}"
                  required={true}
                  type="text"
                  data-cy="phoneNumber"
                  maxLength={14}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label>Password</Label>
                <Input
                  placeholder="Enter Company Password"
                  value={state.password}
                  onChange={(e) =>
                    setState({ ...state, password: e.target.value })
                  }
                  required={true}
                  type="text"
                  data-cy="companyPassword"
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle}>Cancel</Button>
          <Button color="primary" type={'submit'} data-cy="submitCompany">
            {loading ? <Loader height={'40px'} width={'40px'} /> : 'Save'}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}

export default AddNewCompanyModal;
