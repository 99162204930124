const { REACT_APP_BACKEND_SERVER } = process.env;

export default {
  loginAPI: () => `${REACT_APP_BACKEND_SERVER}api/auth/login`,
  logoutAPI: () => `${REACT_APP_BACKEND_SERVER}api/auth/logout`,
  getCompanyAPI: () =>
    `${REACT_APP_BACKEND_SERVER}api/platform/companies/get-company-list`,
  registerCompanyAPI: () =>
    `${REACT_APP_BACKEND_SERVER}api/platform/auth/register-company`,
  approveCompanyAPI: (id) =>
    `${REACT_APP_BACKEND_SERVER}api/platform/companies/approve-company-request/${id}`,
  resetCompanyPassword: (id) =>
    `${REACT_APP_BACKEND_SERVER}api/platform/companies/reset-password/${id}`,
  rejectCompanyAPI: (id) =>
    `${REACT_APP_BACKEND_SERVER}api/platform/companies/reject-company-request/${id}`,
  deleteCompanyAPI: (id) =>
    `${REACT_APP_BACKEND_SERVER}api/platform/companies/delete-company-request/${id}`,
  getCompaniesByIdAPI: (id) =>
    `${REACT_APP_BACKEND_SERVER}api/platform/companies/get-company-details/${id}`,
  getRolesAPI: () =>
    `${REACT_APP_BACKEND_SERVER}api/platform/companies/get-roles`,
  customEmailApi: () =>
    `${REACT_APP_BACKEND_SERVER}api/platform/companies/send-emails-free-template`,
  deleteCompanyApiOld: (id) =>
    `${REACT_APP_BACKEND_SERVER}api/platform/companies/delete-company/${id}`,
  suspendCompanyApi: (id) =>
    `${REACT_APP_BACKEND_SERVER}api/platform/companies/suspend-company/${id}`,
};
