/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { FaArrowDown, FaSort } from 'react-icons/fa';
import { Button, Col, Container, Row } from 'reactstrap';
import moment from 'moment';
import { toast } from 'react-toastify';

import AddNewCompanyModal from '../../shared/components/AddNewCompanyModal';
import Loader from '../../shared/components/loaders/Loader';
import SendEmailModal from '../../shared/components/SendEmailModal';
import StickyHeader from '../../shared/components/StickyHeader';
import CompanyCard from './components/CompanyCard';
import {
  getCompanies,
  getRoles,
  getUnifiedRoles,
} from './CompaniesPage.helper';
import './CompaniesPage.scss';

function CompaniesPage() {
  const companiesListRef = useRef(null);
  const [companies, setCompanies] = useState([]);
  const [sortKey, setSortKey] = useState(null);
  const [orderDirectionAsc, setOrderDirectionAsc] = useState(true);
  const [loading, setLoading] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [roles, setRoles] = useState({
    raw: {},
    unified: {},
  });
  const [sendEmailModal, setSendEmailModal] = useState({
    status: false,
    data: {},
    origin: null,
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      const companies = await getCompanies();
      setCompanies(companies);
    } catch (e) {
      toast.error('Something went wrong');
      console.error(e);
      setLoading(false);
    }
    try {
      const roles = await getRoles();
      setRoles({
        raw: roles,
        unified: getUnifiedRoles(roles),
      });
    } catch (e) {
      toast.error('Something went wrong');
      console.error(e);
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const toggleSort = (key) => {
    if (key === sortKey) {
      setOrderDirectionAsc(!orderDirectionAsc);
    } else {
      setSortKey(key);
      setOrderDirectionAsc(true);
    }
  };

  if (sortKey !== null && companies.length > 0) {
    companies.sort((a, b) => {
      if (sortKey === 'id') return Number(a[sortKey]) - Number(b[sortKey]);
      if (sortKey === 'createdAt')
        return moment(a[sortKey]).unix() - moment(b[sortKey]).unix();
      const aValue = String(a[sortKey]);
      const bValue = String(b[sortKey]);
      return aValue.localeCompare(bValue);
    });
    if (!orderDirectionAsc) companies.reverse();
  }

  return (
    <div className={'CompaniesPage'}>
      <Container>
        <Row>
          <Col md={6}>
            <p className="page-title">Companies</p>
          </Col>
          <Col
            md={6}
            className="d-flex align-items-center justify-content-end gap-3"
          >
            <Button
              color="info"
              size="sm"
              onClick={() =>
                setSendEmailModal({
                  status: true,
                  data: companies,
                  origin: 'main',
                })
              }
            >
              Send email
            </Button>
            <Button
              color={'primary'}
              size="sm"
              onClick={() => setAddModal(true)}
              data-cy="addNewCompany"
            >
              Add New
            </Button>
          </Col>
          <Col md={12}>
            <StickyHeader
              bodyRef={companiesListRef}
              topPositionHeader={0}
              thresholdSticky={0}
            >
              <div className="head d-flex w-100">
                <div
                  className="w-5"
                  role={'button'}
                  onClick={() => toggleSort('id')}
                >
                  <p className="title cursor-pointer">
                    ID <FaSort />
                  </p>
                </div>
                <div
                  className="w-25"
                  role={'button'}
                  onClick={() => toggleSort('name')}
                >
                  <p className="title cursor-pointer ">
                    COMPANY NAME <FaSort />
                  </p>
                </div>
                <div
                  className="w-20"
                  role={'button'}
                  onClick={() => toggleSort('email')}
                >
                  <p className="title cursor-pointer">
                    EMAIL <FaSort />
                  </p>
                </div>
                <div
                  className="w-15 d-flex justify-content-center"
                  role={'button'}
                  onClick={() => toggleSort('createdAt')}
                >
                  <p
                    className="title cursor-pointer"
                    style={{ paddingRight: '0.35rem' }}
                  >
                    ADDED <FaSort />
                  </p>
                </div>
                <div
                  className="w-30"
                  style={{ paddingLeft: '20px' }}
                  role={'button'}
                  onClick={() => toggleSort('status')}
                >
                  <p className="title cursor-pointer">
                    STATUS <FaSort />
                  </p>
                </div>
              </div>
            </StickyHeader>
            {loading && <Loader />}
            {!loading && (
              <div className="companies-list" ref={companiesListRef}>
                {companies.map((companyData, i) => (
                  <CompanyCard
                    data={companyData}
                    fetch={fetchData}
                    key={companyData.id}
                  />
                ))}
              </div>
            )}
          </Col>
        </Row>
      </Container>
      {addModal && (
        <AddNewCompanyModal
          status={addModal}
          toggle={() => {
            setAddModal(!addModal);
            fetchData();
          }}
        />
      )}
      {sendEmailModal.status && (
        <SendEmailModal
          status={sendEmailModal.status}
          data={sendEmailModal.data}
          origin={sendEmailModal.origin}
          toggle={() =>
            setSendEmailModal({
              status: false,
              data: {},
              origin: null,
            })
          }
        />
      )}
    </div>
  );
}

export default CompaniesPage;
