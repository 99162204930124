import companiesIcon from '../../assets/icons/Icon.svg';
import dashboardIcon from '../../assets/icons/Icon2.svg';

export const Menu = [
  {
    title: 'Dashboard',
    icon: dashboardIcon,
    link: '/dashboard',
  },
  {
    title: 'Companies',
    icon: companiesIcon,
    link: '/company',
  },
];
