import axios from 'axios';
import endpoints from '../../services/endpoints';
import { STORAGE_KEY } from '../../services/constants';
import { toast } from 'react-toastify';
import { UserRoles } from '@insightt/insightt-shared';
import { messageConstants } from '../../constants/messages.constants';

export const loginRequest = async (email, password) => {
  let res;
  try {
    res = await axios.post(endpoints.loginAPI(), {
      email,
      password,
      deviceType: 'web',
      deviceToken: '',
    });
  } catch (e) {
    toast.error(e?.response?.data.message);
    return false;
  }
  const { token, user } = res.data;

  if (user.role.type !== UserRoles.SUPER_ADMIN_ROLE) {
    toast.error(messageConstants.ACCESS_DENIED);
    return false;
  }

  window.sessionStorage.setItem(STORAGE_KEY.TOKEN, token);
  window.sessionStorage.setItem('rra-sa-user', JSON.stringify(user));
  return true;
};
