import React, { useEffect, useState } from 'react';
import './CompanyPage.scss';
import { Col, Container, Row } from 'reactstrap';
import BackButton from '../../shared/components/BackButton';
import CompanyBranchDetails from './CompanyBranchDetails';
import CompanyUsersDetails from './CompanyUsersDetails';
import { useParams } from 'react-router-dom';
import { getCompaniesById, getRoles } from './CompanyPage.helper';
import CompanyTopBarDetails from './company-top-bar-details';
import PageSplitter from '../../shared/components/PageSplitter/PageSplitter';
import Loader from '../../shared/components/loaders/Loader';

function CompanyPage() {
  const [state, setState] = useState({ branches: [], users: [] });
  const [tabList, setTabList] = useState({
    options: ['branches', 'users'],
    current: 'branches',
  });
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  const fetchData = () => {
    setIsLoading(true);
    getCompaniesById(id).then((res) => setState(res.companyDetails));
    getRoles().then((res) => setRoles(res));
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <Loader />;
  return (
    <div className={'CompanyPage'}>
      <Container>
        <BackButton className="pb-3" />
        <Row>
          <Col className={'side-info'}>
            <CompanyTopBarDetails data={state} fetch={fetchData} />
          </Col>
        </Row>
        <Row>
          <Col className={'info-container'}>
            <PageSplitter list={tabList} setCurrent={setTabList} />
            {tabList.current === 'branches' && (
              <CompanyBranchDetails data={state.branches} users={state.users} />
            )}
            {tabList.current === 'users' && (
              <CompanyUsersDetails
                data={state.users}
                roles={roles}
                branches={state.branches}
              />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default CompanyPage;
