import React, { useState } from 'react';
import './company-top-bar-details.scss';
import {
  Button,
  Row,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import moment from 'moment';
import ResetPasswordModal from '../../../shared/components/ResetPasswordModal/ResetPasswordModal';
import SendEmailModal from '../../../shared/components/SendEmailModal';
import {
  approveCompany,
  checkCompanyStatus,
  suspendCompany,
  deleteCompany,
} from '../../../shared/helpers/company-helpers';
import { toast } from 'react-toastify';
import { FaCaretDown } from 'react-icons/fa';

function CompanyTopBarDetails({ data = {}, fetch }) {
  const { userAdmin } = data;
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [resetPasswordModal, setResetPasswordModal] = useState({
    status: false,
    data: {},
  });
  const [sendEmailModal, setSendEmailModal] = useState({
    status: false,
    data: {},
  });

  const onApprove = async () => {
    const res = await approveCompany(data.id);
    if (!res) return toast.error('Something went wrong');
    fetch();
    toast.success('Company approved successfully');
  };
  const onSuspend = async () => {
    const res = await suspendCompany(data.id);
    if (!res) return toast.error('Something went wrong');
    fetch();
    toast.success('Company suspended successfully');
  };
  const onDelete = async () => {
    let res = await deleteCompany(data.id);
    if (!res) return toast.error('Something went wrong');
    fetch();
    toast.success('Company deleted successfully');
  };

  return (
    <>
      <Row>
        <Col className={'CompanyTopBarDetails'} xs="5">
          <div className="logo-title-container">
            <div className="logo-container">
              <img
                src={
                  userAdmin?.avatarUrl ??
                  'https://thumbs.dreamstime.com/b/default-avatar-profile-vector-user-profile-default-avatar-profile-vector-user-profile-profile-179376714.jpg'
                }
                alt=""
              />
            </div>
            <div className="info-container">
              <div className="title-container">
                <p className="name">{data.name}</p>
                <p className="">
                  <span>ID:</span> {data.id}
                </p>
                <p className="">
                  <span>Added on: </span>{' '}
                  {moment(data.createdAt).format('DD-MM-YYYY')}
                </p>
              </div>
            </div>
          </div>
        </Col>
        <Col>
          <div className="btn-group">
            <Dropdown
              isOpen={dropDownOpen}
              toggle={() => setDropDownOpen(!dropDownOpen)}
            >
              <DropdownToggle
                tag="div"
                type="button"
                className="header-left d-flex"
              >
                <Button className={checkCompanyStatus(data.status).classname}>
                  {checkCompanyStatus(data.status).label} <FaCaretDown />
                </Button>
              </DropdownToggle>
              <DropdownMenu>
                {data.status !== 'approved' && (
                  <DropdownItem onClick={onApprove}>Activate</DropdownItem>
                )}
                {data.status !== 'suspended' && (
                  <DropdownItem onClick={onSuspend}>Suspend</DropdownItem>
                )}
                {data.status !== 'deleted' && (
                  <DropdownItem onClick={onDelete}>Delete</DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
            <Button
              style={{ margin: '0 1rem', fontWeight: '700' }}
              color={'primary'}
              size="sm"
              onClick={() =>
                setResetPasswordModal({ status: true, data: data })
              }
            >
              Reset Password
            </Button>
            <Button
              style={{ fontWeight: '700' }}
              color={'primary'}
              size="sm"
              onClick={() => setSendEmailModal({ status: true, data: data })}
            >
              Send Email
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="CompanyTopBarDetails">
        <Col xs="3">
          <div className="key-label">
            <p className="key">Email Address</p>
            <p className="label">{data.email}</p>
          </div>
        </Col>
        <Col xs="4">
          <div className="key-label">
            <p className="key">RDN Key</p>
            <p className="label">{data.rdnKey}</p>
          </div>
        </Col>
        <Col xs="5">
          <div className="key-label">
            <p className="key">DRN Key</p>
            <p className="label">{data.drnKey}</p>
          </div>
        </Col>
      </Row>
      {resetPasswordModal.status && (
        <ResetPasswordModal
          status={resetPasswordModal.status}
          data={resetPasswordModal.data}
          toggle={() =>
            setResetPasswordModal({
              status: false,
              data: {},
            })
          }
        />
      )}
      {sendEmailModal.status && (
        <SendEmailModal
          status={sendEmailModal.status}
          data={sendEmailModal.data}
          toggle={() =>
            setSendEmailModal({
              status: false,
              data: {},
            })
          }
        />
      )}
    </>
  );
}

export default CompanyTopBarDetails;
