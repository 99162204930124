import axios from 'axios';
import endpoints from '../../services/endpoints';
import { getAccessToken } from '../../services/token.utils';
import { logoutRequest } from '../../services/http.utils';

export const getCompanies = async () => {
  try {
    let res = await axios.get(endpoints.getCompanyAPI(), {
      headers: { Authorization: `Bearer ${getAccessToken()}` },
    });
    return res.data.companies;
  } catch (e) {
    if (e.response.status === 401) {
      logoutRequest();
    }
    console.error(e);
    return [];
  }
};

export const getRoles = async () => {
  try {
    let res = await axios.get(endpoints.getRolesAPI(), {
      headers: { Authorization: `Bearer ${getAccessToken()}` },
    });
    return res.data.roles;
  } catch (e) {
    if (e.response.status === 401) {
      logoutRequest();
    }
    console.error(e);
    return [];
  }
};

export const getUnifiedRoles = (roles) => {
  let final = {};
  for (const role of roles) {
    final[role.id] = role.name;
  }
  return final;
};

export const resetPassword = async (companyId, newPassword) => {
  try {
    const res = await axios.post(
      endpoints.resetCompanyPassword(companyId),
      { password: newPassword },
      { headers: { Authorization: `Bearer ${getAccessToken()}` } },
    );
    return res.status === 200;
  } catch (e) {
    if (e.response.status === 401) {
      await logoutRequest();
    }
    console.error(e);
    throw e;
  }
};
