import { getAccessToken } from '../../services/token.utils';

const authorizationWithToken = () => ({
  headers: {
    Authorization: `Bearer ${getAccessToken()}`,
  },
});

const setHeaders = {
  authorizationWithToken,
};
export default setHeaders;
